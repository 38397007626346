/**
 * IMPORTS
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Translate } from 'react-i18nify';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import {
  Card,
  CardBody,
} from 'reactstrap';

import TimeoutButton from '../TimeoutButton';

import { completeStep } from '../../actions/nav-actions';
import { getStepDisabled } from '../../selectors';

import '../../css/fireworks.scss';
import partnerLogo from '../../assets/IBblanc.png';

/**
 * FIREWORKS
 */

const Fireworks = () => (
  <div
    className="pyro"
    style={{
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: -100,
    }}
  >
    <div className="before" />
    <div className="after" />
  </div>
);

/**
 * CORE
 */

class End extends Component {
  static stepId = 'end';

  static propTypes = {
    disabled: PropTypes.bool,
    completeStep: PropTypes.func.isRequired,
  };

  static defaultProps = {
    disabled: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      ticksBeforeValidate: 5,
    };

    this.onValidate = this.onValidate.bind(this);
  }

  onValidate() {
    this.props.completeStep(End.stepId);
  }

  render() {
    const { disabled } = this.props;
    const { ticksBeforeValidate } = this.state;

    return (
      <div>
        <Fireworks />
        <Card className="text-center">
          <CardBody>
            <Translate value="End.line1" tag="h3" />
            <Translate value="End.line2" tag="h3" />

            <div className="w-100">
              <TimeoutButton
                buttonProps={{
                  className: 'mr-2 app-button',
                  color: 'primary',
                  size: 'lg',
                  onClick: this.onValidate,
                }}
                ticks={ticksBeforeValidate}
                onTimeout={this.onValidate}
                disabled={disabled}
              >
                <FA icon={faCheck} />
                &nbsp;
                <Translate value="End.ok" />
              </TimeoutButton>
            </div>
          </CardBody>
        </Card>
        <div
          style={{
            position: 'absolute',
            width: '100%',
            left: 0,
            bottom: '2rem',
          }}
        >
          <div
            style={{
              display: 'flex',
              background: '#3563E5',
              justifyContent: 'center',
              alignItems: 'center',
              width: '50%',
              margin: 'auto',
              padding: '1rem 2rem',
              textAlign: 'left',
            }}
          >
            <img
              src={partnerLogo}
              alt="Imagine Belgium logo"
              style={{ marginRight: '2rem' }}
            />
            <span>
              <Translate
                value="End.partnerTitle"
                tag="p"
                style={{
                  fontSize: '1.5rem',
                  marginBottom: 0,
                  color: '#31E8A6',
                  fontFamily: 'League Spartan',
                }}
              />
              <Translate
                value="End.partner"
                tag="p"
                style={{
                  fontSize: '1rem',
                  marginBottom: 0,
                  color: 'white',
                  fontFamily: 'Filson Soft',
                  whiteSpace: 'break-spaces',
                }}
              />
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    disabled: getStepDisabled(state),
  }),
  {
    completeStep,
  },
)(End);
